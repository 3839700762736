

  .gamedev{
    opacity: 0;
    display: inline-block;
  }
  
  .one .gamedev:nth-of-type(1) {
    animation-delay: 2.01s;
  }
  .one .gamedev:nth-of-type(2) {
    animation-delay: 2.05s;
  }
  .one .gamedev:nth-of-type(3) {
    animation-delay: 2.1s;
  }
  .one .gamedev:nth-of-type(4) {
    animation-delay: 2.15s;
  }
  .one .gamedev:nth-of-type(5) {
    animation-delay: 2.2s;
  }
  .one .gamedev:nth-of-type(6) {
    animation-delay: 2.25s;
  }
  .one .gamedev:nth-of-type(7) {
    animation-delay: 2.3s;
  }
  .one .gamedev:nth-of-type(8) {
    animation-delay: 2.35s;
  }
  .one .gamedev:nth-of-type(9) {
    animation-delay: 2.4s;
  }
  .one .gamedev:nth-of-type(10) {
    animation-delay: 2.45s;
  }
  .one .gamedev:nth-of-type(11) {
    animation-delay: 2.5s;
  }
  .one .gamedev:nth-of-type(12) {
    animation-delay: 2.55s;
  }
  .one .gamedev:nth-of-type(13) {
    animation-delay: 2.6s;
  }
  .one .gamedev:nth-of-type(14) {
    animation-delay: 2.65s;
  }
  .one .gamedev:nth-of-type(15) {
    animation-delay: 2.7s;
  }
  .one .gamedev:nth-of-type(16) {
    animation-delay: 2.75s;
  }
  .one .gamedev:nth-of-type(17) {
    animation-delay: 2.8s;
  }
  .one .gamedev:nth-of-type(18) {
    animation-delay: 2.85s;
  }
  .one .gamedev:nth-of-type(19) {
    animation-delay: 2.9s;
  }
  .one .gamedev:nth-of-type(20) {
    animation-delay: 2.95s;
  }
  
  .one .gamedev {
    color: #0091ea;
    text-shadow: 1px 1px 10px #000;
    opacity: 0;
    transform: translate(-150px, -50px) rotate(-180deg) scale(3);
    animation-name: gameDeveloper;
    animation-duration: 10s;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  
  @keyframes gameDeveloper {
    3% {
      transform: translate(20px, 20px) rotate(30deg) scale(.3);
    }
  
    6% {
      transform: translate(0) rotate(0) scale(1);
      opacity: 1;
    }
    40%{
      transform: translate(0) rotate(0) scale(1);
      opacity: 1;
    }
    50%{
      transform: translate(0) rotate(0) scale(1);
      opacity: 0;
    }
    100%{
      transform: translate(0) rotate(0) scale(1);
      opacity: 0;
    }
  }

  .webdev {
    display: inline-block;
  }

  .two .webdev:nth-of-type(1) {
    animation-delay: -3.95s;
  }
  .two .webdev:nth-of-type(2) {
    animation-delay: -3.90s;
  }
  .two .webdev:nth-of-type(3) {
    animation-delay: -3.85s;
  }
  .two .webdev:nth-of-type(4) {
    animation-delay: -3.80s;
  }
  .two .webdev:nth-of-type(5) {
    animation-delay: -3.75s;
  }
  .two .webdev:nth-of-type(6) {
    animation-delay: -3.70s;
  }
  .two .webdev:nth-of-type(7) {
    animation-delay: -3.65s;
  }
  .two .webdev:nth-of-type(8) {
    animation-delay: -3.60s;
  }
  .two .webdev:nth-of-type(9) {
    animation-delay: -3.55s;
  }
  .two .webdev:nth-of-type(10) {
    animation-delay: -3.5s;
  }
  .two .webdev:nth-of-type(11) {
    animation-delay: -3.45s;
  }
  .two .webdev:nth-of-type(12) {
    animation-delay: -3.40s;
  }
  .two .webdev:nth-of-type(13) {
    animation-delay: -3.35s;
  }
  .two .webdev:nth-of-type(14) {
    animation-delay: -3.3s;
  }
  .two .webdev:nth-of-type(15) {
    animation-delay: -3.25s;
  }
  .two .webdev:nth-of-type(16) {
    animation-delay: -3.2s;
  }
  .two .webdev:nth-of-type(17) {
    animation-delay: -3.15s;
  }
  .two .webdev:nth-of-type(18) {
    animation-delay: -3.1s;
  }
  .two .webdev:nth-of-type(19) {
    animation-delay: -3.05s;
  }
  .two .webdev:nth-of-type(20) {
    animation-delay: -3.01s;
  }
  
  .two .webdev {
    color: #0091ea;
    text-shadow: 1px 1px 10px #000;
    opacity: 0;
    transform: translate(-150px, -50px) rotate(-180deg) scale(3);
    animation-name: webDeveloper;
    
    animation-duration: 10s;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  
  @keyframes webDeveloper {
    7%{
      transform: translate(-150px, -50px) rotate(-180deg) scale(3);
      opacity: 0;
    }
    10% {
      transform: translate(20px, 20px) rotate(30deg) scale(.3);
      opacity: 1;
    }
  
    13% {
      transform: translate(0) rotate(0) scale(1);
      opacity: 1;
    }
    45%{
      transform: translate(0) rotate(0) scale(1);
      opacity: 1;
    }
    55%{
      transform: translate(0) rotate(0) scale(1);
      opacity: 0;
    }
    100%{
      transform: translate(0) rotate(0) scale(1);
      opacity: 0;
    }
  }