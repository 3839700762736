body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;
}

.home-header{
  height: 92vh;
}

.home{
  /*padding-top: 90px;
  height: 500px;
  width: 100%;
  background-color: rgba(41, 68, 77, 0.5)*/

  height: 112vh;
  display: flex;
  align-items: center;
  top: -20vh;
}

.jobtitle{
  font-weight: bold;
}

.navbar{
  background-color: #263238bd !important
}

.navbar-fixed{
  z-index:998;
}

.about-text-title{
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 38px;
  padding-bottom: 0px;
  font-weight: 700;
  margin: 0px;
  padding-bottom: 20px;
}

.about-text-header{
  margin-bottom: 80px;
}

.myProfile
{
  padding-top: 0px;
}

.profile-img{
  background-image: url("img/perfil.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}

.img-circle {
  border-radius: 50%;
}

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
}

nav ul li:hover {
  background-color:#0091ea;
}

.sidenav{
  background-color: #263238;
}

.sidenav ul li:hover{
  background-color:#0091ea;
}

.division{
  height: 1px;
  --width: 10%;
}
/*
.parallax-container{
  height: 400px;
}

.parallax-division{
  width: 100%;
  height: auto;
  padding-top: 230px;
}*/

.portfolio{
  padding-top: 40px;
}

.section-header{
  padding-top: 20px;
  padding-bottom: 20px;
}

.section-title{
  text-shadow: 1.2px 1.2px #c0c0c0;
  font-weight: bold;
}

.section-container{
  padding-top: 30px;
}

.portfolio-modal-title{
  padding-top: 12px;
}

.portfolio-modal-title-panel{
  margin: 10px;
  border-bottom:10px solid #263238;
}

.services{
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
  width: 100%;
}

.services-header{
  padding-bottom: 80px;
}

.services-icons{
  font-size: 50px;
  padding-bottom: 20px;
  text-shadow: 1px 1px #c0c0c0;
}

.services-text{
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 38px;
  padding-bottom: 0px;
  font-weight: 500;
  margin: 0px;
  padding-bottom: 70px;
  text-shadow: 1px 1px #c0c0c0;
}

.submit{
  padding-top: 20px;
}

.footer-row{
  padding-top: 20px;
}

.social{
  padding-top: 10px;
  color: white;
  font-size: 40px;
}

.contact-footer{
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  font-size: 29px;
}

.contact-footer-header{
  max-width: 50px;
  min-width: 40px;
  
}

.contact-footer-text{
  --max-width: 550px;
  --min-width: 200px;
}

.contact-row{
  margin-top: -20px;
}

.footer-media{
  min-width: 50px;
}

.modal{
  width: 70%;
  max-height: 85%;
}

@media screen and (min-width: 993px) {
  .portfolio-card{
    height: 420px;
  }
  h1{
    font-family: 'Open Sans', sans-serif;
    font-size: 70px;
    line-height: 90px;
    font-weight: 700;
    margin: 0px;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .animate {
    font-family: 'Open Sans', sans-serif;
    font-size: 36px;
    line-height: 0px;
    padding-top: 30px;
    font-weight: bold;
    margin: 0px;
  }
}

@media screen and (max-width: 992px) {
  .name{
    position:relative;
    text-align: center;
    width:50%;
  }
  h1{
    font-family: 'Open Sans', sans-serif;
    font-size: 40px;
    line-height: 90px;
    font-weight: 700;
    margin: 0px;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .animate {
    font-family: 'Open Sans', sans-serif;
    font-size: 25px;
    line-height: 0px;
    padding-top: 10px;
    font-weight: bold;
    margin: 0px;
  }
}

#cf img {
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

@keyframes cf3FadeInOut {
  0% {
  opacity:1;
}
25% {
opacity:1;
}
75% {
opacity:0;
}
100% {
opacity:0;
}
}

#cf img.bottom{
  max-width: 1024px;
  height: 800px;
}

#cf img.top {
  max-width: 1920px;
  height: 1080px;
  --padding-bottom: 100px;
  animation-name: cf3FadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-direction: alternate;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


