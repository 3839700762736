body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;
}

.home-header{
  height: 92vh;
}

.home{
  /*padding-top: 90px;
  height: 500px;
  width: 100%;
  background-color: rgba(41, 68, 77, 0.5)*/

  height: 112vh;
  display: flex;
  align-items: center;
  top: -20vh;
}

.jobtitle{
  font-weight: bold;
}

.navbar{
  background-color: #263238bd !important
}

.navbar-fixed{
  z-index:998;
}

.about-text-title{
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 38px;
  padding-bottom: 0px;
  font-weight: 700;
  margin: 0px;
  padding-bottom: 20px;
}

.about-text-header{
  margin-bottom: 80px;
}

.myProfile
{
  padding-top: 0px;
}

.profile-img{
  background-image: url(/static/media/perfil.141d2218.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}

.img-circle {
  border-radius: 50%;
}

.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
}

nav ul li:hover {
  background-color:#0091ea;
}

.sidenav{
  background-color: #263238;
}

.sidenav ul li:hover{
  background-color:#0091ea;
}

.division{
  height: 1px;
  --width: 10%;
}
/*
.parallax-container{
  height: 400px;
}

.parallax-division{
  width: 100%;
  height: auto;
  padding-top: 230px;
}*/

.portfolio{
  padding-top: 40px;
}

.section-header{
  padding-top: 20px;
  padding-bottom: 20px;
}

.section-title{
  text-shadow: 1.2px 1.2px #c0c0c0;
  font-weight: bold;
}

.section-container{
  padding-top: 30px;
}

.portfolio-modal-title{
  padding-top: 12px;
}

.portfolio-modal-title-panel{
  margin: 10px;
  border-bottom:10px solid #263238;
}

.services{
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
  width: 100%;
}

.services-header{
  padding-bottom: 80px;
}

.services-icons{
  font-size: 50px;
  padding-bottom: 20px;
  text-shadow: 1px 1px #c0c0c0;
}

.services-text{
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  line-height: 38px;
  padding-bottom: 0px;
  font-weight: 500;
  margin: 0px;
  padding-bottom: 70px;
  text-shadow: 1px 1px #c0c0c0;
}

.submit{
  padding-top: 20px;
}

.footer-row{
  padding-top: 20px;
}

.social{
  padding-top: 10px;
  color: white;
  font-size: 40px;
}

.contact-footer{
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  font-size: 29px;
}

.contact-footer-header{
  max-width: 50px;
  min-width: 40px;
  
}

.contact-footer-text{
  --max-width: 550px;
  --min-width: 200px;
}

.contact-row{
  margin-top: -20px;
}

.footer-media{
  min-width: 50px;
}

.modal{
  width: 70%;
  max-height: 85%;
}

@media screen and (min-width: 993px) {
  .portfolio-card{
    height: 420px;
  }
  h1{
    font-family: 'Open Sans', sans-serif;
    font-size: 70px;
    line-height: 90px;
    font-weight: 700;
    margin: 0px;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .animate {
    font-family: 'Open Sans', sans-serif;
    font-size: 36px;
    line-height: 0px;
    padding-top: 30px;
    font-weight: bold;
    margin: 0px;
  }
}

@media screen and (max-width: 992px) {
  .name{
    position:relative;
    text-align: center;
    width:50%;
  }
  h1{
    font-family: 'Open Sans', sans-serif;
    font-size: 40px;
    line-height: 90px;
    font-weight: 700;
    margin: 0px;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .animate {
    font-family: 'Open Sans', sans-serif;
    font-size: 25px;
    line-height: 0px;
    padding-top: 10px;
    font-weight: bold;
    margin: 0px;
  }
}

#cf img {
  -webkit-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

@-webkit-keyframes cf3FadeInOut {
  0% {
  opacity:1;
}
25% {
opacity:1;
}
75% {
opacity:0;
}
100% {
opacity:0;
}
}

@keyframes cf3FadeInOut {
  0% {
  opacity:1;
}
25% {
opacity:1;
}
75% {
opacity:0;
}
100% {
opacity:0;
}
}

#cf img.bottom{
  max-width: 1024px;
  height: 800px;
}

#cf img.top {
  max-width: 1920px;
  height: 1080px;
  --padding-bottom: 100px;
  -webkit-animation-name: cf3FadeInOut;
          animation-name: cf3FadeInOut;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}





  .gamedev{
    opacity: 0;
    display: inline-block;
  }
  
  .one .gamedev:nth-of-type(1) {
    -webkit-animation-delay: 2.01s;
            animation-delay: 2.01s;
  }
  .one .gamedev:nth-of-type(2) {
    -webkit-animation-delay: 2.05s;
            animation-delay: 2.05s;
  }
  .one .gamedev:nth-of-type(3) {
    -webkit-animation-delay: 2.1s;
            animation-delay: 2.1s;
  }
  .one .gamedev:nth-of-type(4) {
    -webkit-animation-delay: 2.15s;
            animation-delay: 2.15s;
  }
  .one .gamedev:nth-of-type(5) {
    -webkit-animation-delay: 2.2s;
            animation-delay: 2.2s;
  }
  .one .gamedev:nth-of-type(6) {
    -webkit-animation-delay: 2.25s;
            animation-delay: 2.25s;
  }
  .one .gamedev:nth-of-type(7) {
    -webkit-animation-delay: 2.3s;
            animation-delay: 2.3s;
  }
  .one .gamedev:nth-of-type(8) {
    -webkit-animation-delay: 2.35s;
            animation-delay: 2.35s;
  }
  .one .gamedev:nth-of-type(9) {
    -webkit-animation-delay: 2.4s;
            animation-delay: 2.4s;
  }
  .one .gamedev:nth-of-type(10) {
    -webkit-animation-delay: 2.45s;
            animation-delay: 2.45s;
  }
  .one .gamedev:nth-of-type(11) {
    -webkit-animation-delay: 2.5s;
            animation-delay: 2.5s;
  }
  .one .gamedev:nth-of-type(12) {
    -webkit-animation-delay: 2.55s;
            animation-delay: 2.55s;
  }
  .one .gamedev:nth-of-type(13) {
    -webkit-animation-delay: 2.6s;
            animation-delay: 2.6s;
  }
  .one .gamedev:nth-of-type(14) {
    -webkit-animation-delay: 2.65s;
            animation-delay: 2.65s;
  }
  .one .gamedev:nth-of-type(15) {
    -webkit-animation-delay: 2.7s;
            animation-delay: 2.7s;
  }
  .one .gamedev:nth-of-type(16) {
    -webkit-animation-delay: 2.75s;
            animation-delay: 2.75s;
  }
  .one .gamedev:nth-of-type(17) {
    -webkit-animation-delay: 2.8s;
            animation-delay: 2.8s;
  }
  .one .gamedev:nth-of-type(18) {
    -webkit-animation-delay: 2.85s;
            animation-delay: 2.85s;
  }
  .one .gamedev:nth-of-type(19) {
    -webkit-animation-delay: 2.9s;
            animation-delay: 2.9s;
  }
  .one .gamedev:nth-of-type(20) {
    -webkit-animation-delay: 2.95s;
            animation-delay: 2.95s;
  }
  
  .one .gamedev {
    color: #0091ea;
    text-shadow: 1px 1px 10px #000;
    opacity: 0;
    -webkit-transform: translate(-150px, -50px) rotate(-180deg) scale(3);
            transform: translate(-150px, -50px) rotate(-180deg) scale(3);
    -webkit-animation-name: gameDeveloper;
            animation-name: gameDeveloper;
    -webkit-animation-duration: 10s;
            animation-duration: 10s;
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }
  
  @-webkit-keyframes gameDeveloper {
    3% {
      -webkit-transform: translate(20px, 20px) rotate(30deg) scale(.3);
              transform: translate(20px, 20px) rotate(30deg) scale(.3);
    }
  
    6% {
      -webkit-transform: translate(0) rotate(0) scale(1);
              transform: translate(0) rotate(0) scale(1);
      opacity: 1;
    }
    40%{
      -webkit-transform: translate(0) rotate(0) scale(1);
              transform: translate(0) rotate(0) scale(1);
      opacity: 1;
    }
    50%{
      -webkit-transform: translate(0) rotate(0) scale(1);
              transform: translate(0) rotate(0) scale(1);
      opacity: 0;
    }
    100%{
      -webkit-transform: translate(0) rotate(0) scale(1);
              transform: translate(0) rotate(0) scale(1);
      opacity: 0;
    }
  }
  
  @keyframes gameDeveloper {
    3% {
      -webkit-transform: translate(20px, 20px) rotate(30deg) scale(.3);
              transform: translate(20px, 20px) rotate(30deg) scale(.3);
    }
  
    6% {
      -webkit-transform: translate(0) rotate(0) scale(1);
              transform: translate(0) rotate(0) scale(1);
      opacity: 1;
    }
    40%{
      -webkit-transform: translate(0) rotate(0) scale(1);
              transform: translate(0) rotate(0) scale(1);
      opacity: 1;
    }
    50%{
      -webkit-transform: translate(0) rotate(0) scale(1);
              transform: translate(0) rotate(0) scale(1);
      opacity: 0;
    }
    100%{
      -webkit-transform: translate(0) rotate(0) scale(1);
              transform: translate(0) rotate(0) scale(1);
      opacity: 0;
    }
  }

  .webdev {
    display: inline-block;
  }

  .two .webdev:nth-of-type(1) {
    -webkit-animation-delay: -3.95s;
            animation-delay: -3.95s;
  }
  .two .webdev:nth-of-type(2) {
    -webkit-animation-delay: -3.90s;
            animation-delay: -3.90s;
  }
  .two .webdev:nth-of-type(3) {
    -webkit-animation-delay: -3.85s;
            animation-delay: -3.85s;
  }
  .two .webdev:nth-of-type(4) {
    -webkit-animation-delay: -3.80s;
            animation-delay: -3.80s;
  }
  .two .webdev:nth-of-type(5) {
    -webkit-animation-delay: -3.75s;
            animation-delay: -3.75s;
  }
  .two .webdev:nth-of-type(6) {
    -webkit-animation-delay: -3.70s;
            animation-delay: -3.70s;
  }
  .two .webdev:nth-of-type(7) {
    -webkit-animation-delay: -3.65s;
            animation-delay: -3.65s;
  }
  .two .webdev:nth-of-type(8) {
    -webkit-animation-delay: -3.60s;
            animation-delay: -3.60s;
  }
  .two .webdev:nth-of-type(9) {
    -webkit-animation-delay: -3.55s;
            animation-delay: -3.55s;
  }
  .two .webdev:nth-of-type(10) {
    -webkit-animation-delay: -3.5s;
            animation-delay: -3.5s;
  }
  .two .webdev:nth-of-type(11) {
    -webkit-animation-delay: -3.45s;
            animation-delay: -3.45s;
  }
  .two .webdev:nth-of-type(12) {
    -webkit-animation-delay: -3.40s;
            animation-delay: -3.40s;
  }
  .two .webdev:nth-of-type(13) {
    -webkit-animation-delay: -3.35s;
            animation-delay: -3.35s;
  }
  .two .webdev:nth-of-type(14) {
    -webkit-animation-delay: -3.3s;
            animation-delay: -3.3s;
  }
  .two .webdev:nth-of-type(15) {
    -webkit-animation-delay: -3.25s;
            animation-delay: -3.25s;
  }
  .two .webdev:nth-of-type(16) {
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
  }
  .two .webdev:nth-of-type(17) {
    -webkit-animation-delay: -3.15s;
            animation-delay: -3.15s;
  }
  .two .webdev:nth-of-type(18) {
    -webkit-animation-delay: -3.1s;
            animation-delay: -3.1s;
  }
  .two .webdev:nth-of-type(19) {
    -webkit-animation-delay: -3.05s;
            animation-delay: -3.05s;
  }
  .two .webdev:nth-of-type(20) {
    -webkit-animation-delay: -3.01s;
            animation-delay: -3.01s;
  }
  
  .two .webdev {
    color: #0091ea;
    text-shadow: 1px 1px 10px #000;
    opacity: 0;
    -webkit-transform: translate(-150px, -50px) rotate(-180deg) scale(3);
            transform: translate(-150px, -50px) rotate(-180deg) scale(3);
    -webkit-animation-name: webDeveloper;
            animation-name: webDeveloper;
    
    -webkit-animation-duration: 10s;
    
            animation-duration: 10s;
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
  }
  
  @-webkit-keyframes webDeveloper {
    7%{
      -webkit-transform: translate(-150px, -50px) rotate(-180deg) scale(3);
              transform: translate(-150px, -50px) rotate(-180deg) scale(3);
      opacity: 0;
    }
    10% {
      -webkit-transform: translate(20px, 20px) rotate(30deg) scale(.3);
              transform: translate(20px, 20px) rotate(30deg) scale(.3);
      opacity: 1;
    }
  
    13% {
      -webkit-transform: translate(0) rotate(0) scale(1);
              transform: translate(0) rotate(0) scale(1);
      opacity: 1;
    }
    45%{
      -webkit-transform: translate(0) rotate(0) scale(1);
              transform: translate(0) rotate(0) scale(1);
      opacity: 1;
    }
    55%{
      -webkit-transform: translate(0) rotate(0) scale(1);
              transform: translate(0) rotate(0) scale(1);
      opacity: 0;
    }
    100%{
      -webkit-transform: translate(0) rotate(0) scale(1);
              transform: translate(0) rotate(0) scale(1);
      opacity: 0;
    }
  }
  
  @keyframes webDeveloper {
    7%{
      -webkit-transform: translate(-150px, -50px) rotate(-180deg) scale(3);
              transform: translate(-150px, -50px) rotate(-180deg) scale(3);
      opacity: 0;
    }
    10% {
      -webkit-transform: translate(20px, 20px) rotate(30deg) scale(.3);
              transform: translate(20px, 20px) rotate(30deg) scale(.3);
      opacity: 1;
    }
  
    13% {
      -webkit-transform: translate(0) rotate(0) scale(1);
              transform: translate(0) rotate(0) scale(1);
      opacity: 1;
    }
    45%{
      -webkit-transform: translate(0) rotate(0) scale(1);
              transform: translate(0) rotate(0) scale(1);
      opacity: 1;
    }
    55%{
      -webkit-transform: translate(0) rotate(0) scale(1);
              transform: translate(0) rotate(0) scale(1);
      opacity: 0;
    }
    100%{
      -webkit-transform: translate(0) rotate(0) scale(1);
              transform: translate(0) rotate(0) scale(1);
      opacity: 0;
    }
  }
